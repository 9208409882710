.centerContent p {
  text-align: center;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1.5em;
          margin-block-end: 1.5em;
}
.centerContent p:last-of-type {
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

form {
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.skip-step {
  font-size: 14px;
  margin-top: 1.5em;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-bottom: 0;
}
.skip-step a,
.skip-step button {
  font-size: 14px;
}

.sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.card-header-img {
  display: block;
  text-align: center;
}

#request-failed a {
  text-decoration: none;
}
#request-failed .support-center-info {
  text-align: left;
  margin-top: 2em;
  margin-bottom: 1em;
}
#request-failed .support-center-info + .support-center-info {
  margin-top: 1em;
}
#request-failed .info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
#request-failed .info span {
  margin-right: 8px;
}
#request-failed .clock-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
}

#request-failed .full-width-btn,
#request-success .full-width-btn {
  margin-top: 1em !important;
}