.finra-redeem-page .voucher-wrap .error-message {
  color: #D24040;
  margin-bottom: 16px;
}

.error-message a {
  color: #d24040 !important;
}

.finra-wrapper .appbar-logo img {
  margin: 0 !important;
}

.finra-secondary-wrapper[data-component=AppBar] .home-link-wrapper {
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 860px) {
  .finra-secondary-wrapper[data-component=AppBar] .home-link-wrapper {
    height: 52px;
  }
  .finra-secondary-wrapper[data-component=AppBar] .layout-home-links .util-bar {
    margin: 0;
  }
  [data-component=AppBar] .layout-home-links .util-bar #log-btn {
    padding: 0.75rem 1rem;
    margin-right: 0;
  }
}
.finra-redeem-page .redeem-wrap .ui-radio-group-stacked .ui-radio-item {
  border: none;
}
.finra-redeem-page .redeem-wrap .bureau-select {
  margin-bottom: 8px;
}

.finra-redeem-page .foot-wrap {
  margin-top: 32px;
}
.finra-redeem-page .foot-wrap .bold {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}