[data-component=Button][data-component-type=icon], .text-long-form .rating-colors-legend .rating-row .rating-icon, .text-long-form .rating-ranges-table .rating-row .rating-icon, .input-helper-container .error-message > .icon-container,
.form-error-container .error-message > .icon-container, [data-component=Image], [data-component=Icon] {
  line-height: 0.1em;
  display: inline-block;
}

[data-component=Input] input[type=radio], .sr-only,
.accessibility-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.non-mobile-padding-horizontal {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media screen and (max-width: 1023px) {
  .mobile-padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mobile-padding-offset {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .mobile-left-padding-offset {
    margin-left: -1rem;
  }
  .mobile-left-padding {
    padding-left: 1rem;
  }
  .mobile-right-padding-offset {
    margin-right: -1rem;
  }
}
.responsive-left-padding, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
  padding-left: 1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
    padding-left: 1.5rem;
  }
}

.responsive-left-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
  padding-left: 0.325rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
    padding-left: 1.125rem;
  }
}

.responsive-left-padding-offset, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
  margin-left: -1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-offset, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
    margin-left: -1.5rem;
  }
}

.responsive-left-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
  margin-left: -0.65rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
    margin-left: -1.125rem;
  }
}

.responsive-right-padding, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .text-container, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
  padding-right: 1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .text-container, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
    padding-right: 1.5rem;
  }
}

.responsive-right-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
  padding-right: 0.325rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
    padding-right: 1.125rem;
  }
}

.responsive-right-padding-offset, [data-component=DataList] > *, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
  margin-right: -1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-offset, [data-component=DataList] > *, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
    margin-right: -1.5rem;
  }
}

.responsive-right-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
  margin-right: -0.65rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
    margin-right: -1.125rem;
  }
}

.nowrap {
  white-space: nowrap;
}

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .non-mobile-only {
    display: none;
  }
}

[data-component=Button][data-component-type=icon], .text-long-form .rating-colors-legend .rating-row .rating-icon, .text-long-form .rating-ranges-table .rating-row .rating-icon, .input-helper-container .error-message > .icon-container,
.form-error-container .error-message > .icon-container, [data-component=Image], [data-component=Icon] {
  line-height: 0.1em;
  display: inline-block;
}

[data-component=Input] input[type=radio], .sr-only,
.accessibility-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.non-mobile-padding-horizontal {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media screen and (max-width: 1023px) {
  .mobile-padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mobile-padding-offset {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .mobile-left-padding-offset {
    margin-left: -1rem;
  }
  .mobile-left-padding {
    padding-left: 1rem;
  }
  .mobile-right-padding-offset {
    margin-right: -1rem;
  }
}
.responsive-left-padding, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
  padding-left: 1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
    padding-left: 1.5rem;
  }
}

.responsive-left-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
  padding-left: 0.325rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
    padding-left: 1.125rem;
  }
}

.responsive-left-padding-offset, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
  margin-left: -1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-offset, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .datalistitem-row, [data-component=DataList] .form-container, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
    margin-left: -1.5rem;
  }
}

.responsive-left-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
  margin-left: -0.65rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
    margin-left: -1.125rem;
  }
}

.responsive-right-padding, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .text-container, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
  padding-right: 1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding, [data-component=DataList][data-row-icon-size=l] .expandable-content, [data-component=DataList] .text-container, [data-component=DataList] .form-container, .responsive-padding, [data-component=Section] > .footer, [data-component=Section] > .body, [data-component=Layout][data-layout~=col] > *, .sticky-section-header > * {
    padding-right: 1.5rem;
  }
}

.responsive-right-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
  padding-right: 0.325rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
    padding-right: 1.125rem;
  }
}

.responsive-right-padding-offset, [data-component=DataList] > *, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
  margin-right: -1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-offset, [data-component=DataList] > *, .responsive-padding-offset, [data-component=Layout][data-layout~=col] {
    margin-right: -1.5rem;
  }
}

.responsive-right-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
  margin-right: -0.65rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
    margin-right: -1.125rem;
  }
}

.nowrap {
  white-space: nowrap;
}

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .non-mobile-only {
    display: none;
  }
}

.max-width {
  max-width: 1024px;
  padding: 0 24px;
  margin: 0 auto;
}

#myFICOApp {
  height: 100vh;
  width: 100vw;
}

.myf-content {
  padding-top: 24px;
  padding-bottom: 24px;
}

.size_72 {
  display: inline-block;
  width: 72px;
  height: 72px;
}

.topSpacing {
  margin-top: 1em;
}

.block {
  display: block;
}

.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin: 0 auto;
}

/* overrides */
.expandable-content {
  display: block;
}

[data-component=DataList][data-row-icon-size=s] [data-component=Icon] {
  width: 1.5rem;
  height: 1.5rem;
}

.help-list {
  list-style: none;
  padding: 0;
}
.help-list li.rating-row {
  margin-bottom: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.help-list.rating-ranges-table [data-component=Icon] {
  width: 42px;
  height: 42px;
}
.help-list .rating-icon {
  margin-right: 8px;
}
.help-list .rating-text .key {
  display: block;
}
.help-list .rating-text .value {
  color: #5C6C75;
}

[data-component=DataList][data-row-icon-size=s] > li:first-child() .text-container,
.expandable-content [data-component=DataList] > li:first-child() .text-container {
  border-top: none !important;
}

.icon-expand {
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.react-slidedown.expandable-content {
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

/* overrides */
/* copy and paste for page */
/* copy and paste for page */
[data-component=Page] {
  background-color: #F7F7F8;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}
.usermenu-is-visible [data-component=Page] {
  overflow: hidden;
}
[data-component=Page] [data-component=PageHeader] {
  z-index: 1;
  position: sticky;
  top: 0;
}
@media screen and (max-width: 1023px) {
  [data-component=Page] [data-component=PageContent] > .page-content [data-component=Card]:first-of-type {
    border-top: none;
  }
}
@media screen and (min-width: 1024px) {
  [data-component=Page] [data-component=PageContent] {
    border-top: 1px solid #dadadc;
  }
}
[data-component=Page] [data-component=PageContent] .page-content {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}
[data-component=Page] [data-component=PageContent] .page-content:last-of-type {
  padding-bottom: 3rem;
}
@media screen and (min-width: 1024px) {
  [data-component=Page] [data-component=PageContent] .page-content {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  [data-component=Page] [data-component=PageContent] .page-content:first-of-type {
    padding-top: 1.5rem;
  }
  [data-component=Page] [data-component=PageContent] .page-content:last-of-type {
    padding-bottom: 1.5rem;
  }
}

.sticky-section-header {
  position: sticky;
  top: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.sticky-section-header + * {
  -webkit-transition: margin-top 0.25s;
  transition: margin-top 0.25s;
}
.sticky-section-header.sticky {
  z-index: 1;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
}
.sticky-section-header.sticky + * {
  margin-top: 1.625rem;
}

.page-section-header {
  padding-bottom: 1rem;
}
.page-section-header .inner-container {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1024px) {
  .page-section-header .inner-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .page-section-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.page-section-header .text-container .title {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.page-section-header .tablist-container {
  margin-top: 1rem;
}
@media screen and (min-width: 1024px) {
  .page-section-header .tablist-container {
    margin-top: 0;
  }
}

/* end copy and paste for page */
/** Copy and Paste for Layout **/
/** Copy and Paste for Layout **/
[data-component=Layout] > * > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -ms-flex-positive: 1;
      flex-grow: 1;
}
@media screen and (min-width: 1024px) {
  [data-component=Layout][data-layout~=collapse] {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  [data-component=Layout][data-layout~=collapse] > * {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -ms-flex-positive: 1;
        flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  [data-component=Layout][data-layout~=collapse] .sidebar {
    -ms-flex-preferred-size: 320px;
        flex-basis: 320px;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0;
    -ms-flex-positive: 0;
        flex-grow: 0;
  }
}
[data-component=Layout][data-layout~=match-heights] > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 1023px) {
  [data-component=Layout][data-layout~=match-heights] > * > * {
    width: 100%;
  }
}
[data-component=Layout][data-layout~=col] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
[data-component=Layout][data-layout~=col] > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}
[data-component=Layout][data-layout~=col-tight] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
[data-component=Layout][data-layout~=col-tight] > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media screen and (max-width: 1023px) {
  .non-mobile-only {
    display: none;
  }
}

/**Copy and paste ends for Layout **/
/** Override for Layout**/
@media screen and (max-width: 1023px) {
  .sidenav-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 44px;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .menu-title {
    position: relative;
    top: 2px;
  }
  .sidenav {
    position: absolute;
    background: #fff;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    top: 0;
  }
  .sidenav.is-open {
    display: block;
  }
  .sidenav [data-component=NavBar] {
    padding-top: 44px;
  }
  .sidenav [data-component=NavBar][data-style=vertical] .link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sidenav button {
    margin: 2px 10px 0 2px;
  }
}
/** Copy and paste for Section */
/** Copy and paste for Section */
@media screen and (max-width: 1023px) {
  [data-component=Section] > .body {
    border-top: 1px solid #E7E7E9;
    border-bottom: 1px solid #E7E7E9;
    background-color: white;
  }
}
@media screen and (max-width: 1023px) {
  [data-component=Section] + [data-component=Section] {
    margin-top: 1rem;
  }
}
[data-component=Section] > .footer {
  padding-top: 0.5rem;
  margin-bottom: 1rem;
}
[data-component=Section] .disclaimer {
  font-size: 14px;
  line-height: 20px;
  color: #5C6C75;
}
[data-component=Section] .disclaimer p {
  margin: 0;
}

/* copy and paste for ICON */
/* copy and paste for ICON */
[data-component=Icon] {
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  width: 1.5rem;
  height: 1.5rem;
}
[data-row-icon-size=l] [data-component=Icon], [data-component=Icon][data-icon-size=l] {
  width: 3rem;
  height: 3rem;
}
[data-component=Icon][data-icon-height=m] {
  height: 1.5rem;
  width: auto;
}
[data-icon-size=xs] [data-component=Icon] {
  width: 0.875rem;
  height: 0.875rem;
}
[data-icon-size=s] [data-component=Icon] {
  width: 1rem;
  height: 1rem;
}
[data-row-icon-type=report] [data-component=Icon] {
  height: 80px;
  width: 60px;
}
[data-component=Icon].inline {
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
}
[data-component=Icon] .alert-icon-background {
  fill: #F7F7F8;
}

/** Copy and paste for images */
/** Copy and paste for images */
[data-component=Image] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/** Copy and paste for delta */
/** Copy and paste for delta */
[data-component=Delta] {
  display: inline-block;
  text-align: center;
  min-width: 1.75rem;
  font-size: 0.875rem;
  line-height: 20px;
  padding: 2px 6px;
  border-radius: 3px;
  background-clip: padding-box;
}
[data-component=Delta][data-impact=positive] {
  -webkit-box-shadow: inset 0 0 0 1px #4db747;
  box-shadow: inset 0 0 0 1px #4db747;
}
[data-component=Delta][data-impact=negative] {
  -webkit-box-shadow: inset 0 0 0 1px #d24040;
  box-shadow: inset 0 0 0 1px #d24040;
}

/** copy and paste for datalist **/
/** copy and paste for datalist **/
[data-component=DataList] {
  list-style: none;
  padding: 0;
  margin: 0;
}
[data-component=DataList] .just-modified {
  -webkit-animation-name: highlightFade;
          animation-name: highlightFade;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
[data-component=DataList] .form-container {
  border-left: 4px solid #0A6DE6;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
[data-component=DataList] form {
  display: block;
}
[data-component=DataList] form > .title {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 1rem;
}
[data-component=DataList] form .action-container [data-component=Button] + [data-component=Button] {
  margin-left: 1rem;
}
[data-component=DataList] .action-container {
  margin-right: -0.75rem;
}
[data-component=DataList] .action-container > [data-component-style=text]:last-child {
  margin-right: 0.5rem;
}
[data-component=DataList] > *:not(:last-of-type) .text-container {
  border-bottom: 1px solid #dadadc;
}
[data-component=DataList] [data-component=Header] .text-container {
  border: none;
}
[data-component=DataList][data-type=attribute] {
  margin-top: -1rem;
}
[data-component=DataList][data-type=attribute] .datalistitem-row {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
[data-component=DataList][data-type=attribute] [data-component=Icon] {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 1rem;
}
@media screen and (min-width: 1024px) {
  [data-component=DataList][data-type=attribute] [data-component=Icon] {
    margin-top: 20px;
  }
}
[data-component=DataList] [data-key] {
  color: #5C6C75;
  font-size: 0.875rem;
  line-height: 20px;
  margin-bottom: 0.25rem;
}
[data-component=DataList] [data-value] {
  font-size: 1rem;
  line-height: 1.5rem;
}
[data-component=DataList] [data-supplemental] {
  color: #5C6C75;
  font-size: 0.875rem;
  line-height: 20px;
  padding-top: 0.75rem;
}
[data-component=DataList] .icon-container {
  line-height: 0.01em;
  padding-right: 0.5rem;
}
[data-component=DataList] .icon-container svg {
  color: #5C6C75;
}
[data-component=DataList] .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -ms-flex-positive: 1;
      flex-grow: 1;
}
[data-component=DataList] .text-container .text {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -ms-flex-positive: 1;
      flex-grow: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media screen and (min-width: 1024px) {
  [data-component=DataList] .text-container .text {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
[data-component=DataList] .datalistitem-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  [data-component=DataList][data-row-icon-size=l] .expandable-content {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  [data-component=DataList][data-row-icon-size=l] .expandable-content {
    padding-left: 5rem;
  }
}
[data-component=DataList][data-type=insights-attribute] [data-supplemental] {
  background-color: orange;
}
[data-component=DataList][data-type=insights-attribute] * {
  -webkit-box-shadow: inset 0 0 0 2px rgba(0, 128, 0, 0.5);
          box-shadow: inset 0 0 0 2px rgba(0, 128, 0, 0.5);
}
[data-component=Header] + [data-component=DataList] {
  margin-top: -1rem;
}
@media screen and (min-width: 1024px) {
  [data-component=Header] + [data-component=DataList] {
    margin-top: -20px;
  }
}

.has-thumbnail {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.article-featured-image {
  display: inline-block;
  width: 8rem;
  height: 4.5rem;
  background-position: center;
  background-size: cover;
  margin-right: 1rem;
  background-color: rgb(167, 167, 167);
}

@-webkit-keyframes highlightFade {
  0% {
    background-color: transparent;
    -webkit-box-shadow: -1.5rem 0 transparent;
    box-shadow: -1.5rem 0 transparent;
  }
  10% {
    background-color: #FFFBE5;
    -webkit-box-shadow: -1.5rem 0 #FFFBE5;
    box-shadow: -1.5rem 0 #FFFBE5;
  }
  90% {
    background-color: #FFFBE5;
    -webkit-box-shadow: -1.5rem 0 #FFFBE5;
    box-shadow: -1.5rem 0 #FFFBE5;
  }
  100% {
    background-color: transparent;
    -webkit-box-shadow: -1.5rem 0 transparent;
    box-shadow: -1.5rem 0 transparent;
  }
}

@keyframes highlightFade {
  0% {
    background-color: transparent;
    -webkit-box-shadow: -1.5rem 0 transparent;
    box-shadow: -1.5rem 0 transparent;
  }
  10% {
    background-color: #FFFBE5;
    -webkit-box-shadow: -1.5rem 0 #FFFBE5;
    box-shadow: -1.5rem 0 #FFFBE5;
  }
  90% {
    background-color: #FFFBE5;
    -webkit-box-shadow: -1.5rem 0 #FFFBE5;
    box-shadow: -1.5rem 0 #FFFBE5;
  }
  100% {
    background-color: transparent;
    -webkit-box-shadow: -1.5rem 0 transparent;
    box-shadow: -1.5rem 0 transparent;
  }
}
/* datalist copy ends*/
/*Overrride*/
[data-component=DataList] > *:not(:last-of-type) .text-container.isExpanded {
  border: none;
}

[data-component=DataList] > *:not(:last-of-type) .text-container.no-bottom-border {
  border: none;
}

@media screen and (max-width: 1023px) {
  #myf-main > .page-content:first-of-type {
    padding-top: 0;
  }
}
.input-password-toggle-button-container {
  position: absolute;
  top: 1.3125rem;
  top: 0.875rem;
  right: 1rem;
}

input[type=range] {
  width: 100%;
}

input[type=number] {
  webkit-appearance: none;
  border: none;
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[data-component=Radiogroup] + [data-component=Input] {
  margin-top: -1rem;
}

[data-component=Input] {
  position: relative;
  margin-bottom: 1rem;
}
[data-component=Input] > :disabled {
  background-color: transparent;
}
[data-component=Input] .input-label {
  pointer-events: none;
  color: #81919C;
  -webkit-transition: all 0.125s;
  transition: all 0.125s;
  position: absolute;
  font-size: 20px;
  line-height: 26px;
  top: 1.5625rem;
  left: 1rem;
  font-size: 20px;
}
[data-component=Input] select:not([disabled]) {
  cursor: pointer;
}
[data-component=Input] select[data-option=""] {
  color: transparent;
}
[data-component=Input] select[data-option=""] ~ .input-label {
  color: #5C6C75;
  font-size: 1rem;
  Top: 1.75rem;
}
[data-component=Input] select ~ .input-label {
  font-size: 0.875rem;
  top: 0.5rem;
}
[data-component=Input] select:disabled ~ .input-trailing-icon-container .expand {
  display: none;
}
[data-component=Input] select:disabled ~ .input-trailing-icon-container .locked {
  color: #5C6C75;
  display: inline-block;
}
[data-component=Input] select ~ .input-trailing-icon-container {
  color: #0A6DE6;
}
[data-component=Input] select ~ .input-trailing-icon-container > .expand {
  margin-top: 0.125rem;
  display: inline-block;
}
[data-component=Input] input {
  margin: 0;
}
[data-component=Input] input:not(:-moz-placeholder-shown) + .input-label {
  font-size: 0.875rem;
  top: 0.5rem;
  color: #695ECC;
}
[data-component=Input] input:not(:-ms-input-placeholder) + .input-label {
  font-size: 0.875rem;
  top: 0.5rem;
  color: #695ECC;
}
[data-component=Input] input:not(:placeholder-shown) + .input-label {
  font-size: 0.875rem;
  top: 0.5rem;
  color: #695ECC;
}
[data-component=Input] input:focus:not(:-moz-read-only) + .input-label {
  color: #695ECC;
  top: 0.5rem;
  font-size: 0.875rem;
}
[data-component=Input] input:focus:not(:read-only) + .input-label {
  color: #695ECC;
  top: 0.5rem;
  font-size: 0.875rem;
}
[data-component=Input] input:disabled {
  cursor: default;
}
[data-component=Input] input:disabled:hover ~ * {
  -webkit-animation: wiggle 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: wiggle 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
@-webkit-keyframes wiggle {
  10%, 90% {
    -webkit-transform: translate(-1px, 0);
            transform: translate(-1px, 0);
  }
  20%, 80% {
    -webkit-transform: translate(1px, 0);
            transform: translate(1px, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  40%, 60% {
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
  }
}
@keyframes wiggle {
  10%, 90% {
    -webkit-transform: translate(-1px, 0);
            transform: translate(-1px, 0);
  }
  20%, 80% {
    -webkit-transform: translate(1px, 0);
            transform: translate(1px, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  40%, 60% {
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
  }
}
[data-component=Input] input:-webkit-autofill:not(:placeholder-shown) + .input-label {
  color: #695ECC;
  font-size: 0.875rem;
  top: 0.5rem;
}
[data-component=Input] input:not([type=range]),
[data-component=Input] select {
  font-size: 20px;
  line-height: 26px;
  border: 1px solid #81919C;
  padding: 1rem;
  padding-top: 2rem;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: none;
  height: 70px;
}
[data-component=Input] input:not([type=range]):focus,
[data-component=Input] select:focus {
  border: 2px solid #0A6DE6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
[data-component=Input] input[type=text]:-moz-read-only, [data-component=Input] input[type=email]:-moz-read-only, [data-component=Input] input[type=password]:-moz-read-only {
  border: none;
  padding: none;
}
[data-component=Input] input[type=text]:read-only,
[data-component=Input] input[type=email]:read-only,
[data-component=Input] input[type=password]:read-only {
  border: none;
  padding: none;
}
[data-component=Input] input[type=text]:-moz-read-only ~ .input-trailing-icon-container, [data-component=Input] input[type=email]:-moz-read-only ~ .input-trailing-icon-container, [data-component=Input] input[type=password]:-moz-read-only ~ .input-trailing-icon-container {
  color: #5C6C75;
}
[data-component=Input] input[type=text]:read-only ~ .input-trailing-icon-container, [data-component=Input] input[type=text]:disabled ~ .input-trailing-icon-container,
[data-component=Input] input[type=email]:read-only ~ .input-trailing-icon-container,
[data-component=Input] input[type=email]:disabled ~ .input-trailing-icon-container,
[data-component=Input] input[type=password]:read-only ~ .input-trailing-icon-container,
[data-component=Input] input[type=password]:disabled ~ .input-trailing-icon-container {
  color: #5C6C75;
}
[data-component=Input] input[type=text]:-moz-read-only ~ .input-trailing-icon-container > .locked, [data-component=Input] input[type=email]:-moz-read-only ~ .input-trailing-icon-container > .locked, [data-component=Input] input[type=password]:-moz-read-only ~ .input-trailing-icon-container > .locked {
  display: inline-block;
}
[data-component=Input] input[type=text]:read-only ~ .input-trailing-icon-container > .locked, [data-component=Input] input[type=text]:disabled ~ .input-trailing-icon-container > .locked,
[data-component=Input] input[type=email]:read-only ~ .input-trailing-icon-container > .locked,
[data-component=Input] input[type=email]:disabled ~ .input-trailing-icon-container > .locked,
[data-component=Input] input[type=password]:read-only ~ .input-trailing-icon-container > .locked,
[data-component=Input] input[type=password]:disabled ~ .input-trailing-icon-container > .locked {
  display: inline-block;
}
[data-component=Input][data-type=text-and-range] input[type=text] {
  padding-bottom: 3rem;
}
[data-component=Input][data-type=text-and-range] .input-range-container {
  position: absolute;
  top: 4rem;
  left: 1rem;
  right: 1rem;
}
[data-component=Input][data-type=radio] * {
  -webkit-box-shadow: inset 0 0 0 2px rgba(255, 0, 0, 0.5);
          box-shadow: inset 0 0 0 2px rgba(255, 0, 0, 0.5);
}
[data-component=Input][data-type=radio] .radio-outer {
  stroke-width: 1px;
}
[data-component=Input][data-type=radio] .radio-input {
  display: inline-block;
  margin-right: 0.5rem;
}
[data-component=Input][data-type=radio] .radio-label {
  padding-top: 0.0625rem;
}
[data-component=Input][data-type=radio] label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
[data-component=Input][data-type=radio] label:hover {
  cursor: pointer;
}
[data-component=Input][data-type=radio] input + label .radio-input {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  -ms-flex-positive: 0;
      flex-grow: 0;
  color: #0A6DE6;
  border-radius: 50%;
  height: 20px;
}
[data-component=Input][data-type=radio] input + label .radio-input .radio-inner {
  opacity: 0;
}
[data-component=Input][data-type=radio] input:checked + label .radio-input .radio-inner {
  opacity: 1;
}
[data-component=Input][data-type=radio] input:focus + label .radio-input {
  -webkit-box-shadow: 0 0 0 0.125rem white, 0 0 0 0.25rem #005fcc;
  box-shadow: 0 0 0 0.125rem white, 0 0 0 0.25rem #005fcc;
}
[data-component=Input] .input-trailing-icon-container {
  pointer-events: none;
  position: absolute;
  top: 1.3125rem;
  right: 1rem;
}
[data-component=Input] .input-helper-container > * {
  padding-left: 1rem;
  padding-right: 1rem;
}
[data-component=Input] .error-message {
  color: #d24040;
  font-size: 16px;
  line-height: 24px;
}
[data-component=Input] .error-message .text-container {
  border: none;
}
[data-component=Input][data-valid=false] .input-helper-container .description {
  display: none;
}
[data-component=Input]:not([data-valid=false]) .input-helper-container .error-message {
  display: none;
}

.description {
  font-size: 0.875rem;
  color: #5C6C75;
  margin-top: 0.5rem;
}

.input-helper-container,
.form-error-container {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #5C6C75;
}
.input-helper-container .error-message,
.form-error-container .error-message {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: initial;
  -ms-flex-align: initial;
  align-items: initial;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
[data-component=Radiogroup] fieldset {
  position: relative;
  border: none;
  margin: 0;
  padding: 0;
}
[data-component=Radiogroup] fieldset .input-trailing-icon-container {
  top: -1.5rem;
  right: 0.125rem;
}
[data-component=Radiogroup] fieldset .legend {
  background-color: pink;
  color: #1F323F;
  margin-bottom: 1rem;
}
[data-component=Radio] + * {
  margin-top: -1rem;
}
[data-component=Radio] [data-component=Input] {
  margin-right: 1rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.form-error-container {
  margin-bottom: 1rem;
  color: #d24040;
}

[data-editable] .form-container {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

input[type=checkbox]:focus {
  outline: 2px solid #0A6DE6;
}

body {
  color: #1F323F;
}
body * {
  font-family: "Outfit", sans-serif;
}

p {
  -webkit-margin-before: 1rem;
          margin-block-start: 1rem;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
  line-height: 1.5em;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

.text-long-form {
  color: #1F323F;
}
.text-long-form img {
  max-width: 100%;
}
.text-long-form h1, .text-long-form h2, .text-long-form h3, .text-long-form h4 {
  color: #1F323F;
  font-weight: 500;
}
.text-long-form h1 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 1rem;
}
.text-long-form h2 {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 1em;
}
.text-long-form h3 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}
.text-long-form ul, .text-long-form ol {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-padding-start: 1rem;
          padding-inline-start: 1rem;
}
.text-long-form ul li + li, .text-long-form ol li + li {
  margin-top: 0.5em;
}
.text-long-form .rating-ranges-table {
  list-style: none;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
.text-long-form .rating-ranges-table .rating-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.text-long-form .rating-ranges-table .rating-row + .rating-row {
  margin-top: 0.75rem;
}
.text-long-form .rating-ranges-table .rating-row .rating-icon {
  margin-right: 0.5rem;
}
.text-long-form .rating-ranges-table .rating-row .rating-icon svg {
  width: 2.625rem;
  height: 2.625rem;
}
.text-long-form .rating-ranges-table .rating-row .rating-text {
  line-height: 1.25rem;
}
.text-long-form .rating-ranges-table .rating-row .rating-text > * {
  display: block;
}
.text-long-form .rating-ranges-table .rating-row .rating-text .value {
  color: #5C6C75;
}
.text-long-form .rating-colors-legend {
  list-style: none;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  padding-left: 0;
}
.text-long-form .rating-colors-legend > li {
  padding-left: 0;
}
.text-long-form .rating-colors-legend li + li {
  margin-top: 0.5em;
}
.text-long-form .rating-colors-legend .rating-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.text-long-form .rating-colors-legend .rating-row .rating-icon {
  margin-right: 0.5rem;
}
.text-long-form .rating-colors-legend .rating-row .rating-icon svg {
  width: 1.5rem;
  height: 1.5rem;
}
.text-long-form .rating-colors-legend .rating-row .rating-text {
  line-height: 1.25rem;
}

.text-primary {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-secondary {
  color: #5C6C75;
  font-size: 0.875rem;
  line-height: 20px;
}
sup {
  font-size: 0.5em;
  line-height: 1em;
}

.disclaimer {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5C6C75;
}

ul.unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.icon-lock-shackle {
  -webkit-animation: open 1.5s ease-in-out normal;
          animation: open 1.5s ease-in-out normal;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes open {
  0%, 10% {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
  20%, 100% {
    -webkit-transform: translate(0%, -10px);
            transform: translate(0%, -10px);
  }
}

@keyframes open {
  0%, 10% {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
  20%, 100% {
    -webkit-transform: translate(0%, -10px);
            transform: translate(0%, -10px);
  }
}
.action-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
@media screen and (max-width: 1023px) {
  .action-container {
    margin-right: -0.5rem;
  }
}
.action-container > :first-child[data-component-style=text] {
  margin-left: -6px;
}
.action-container > :first-child.block {
  -webkit-margin-after: 0.5rem;
          margin-block-end: 0.5rem;
}
@media screen and (min-width: 1024px) {
  .action-container .mobile {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .action-container .non-mobile {
    display: none;
  }
}

.action-container-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.75rem;
}
.action-container-container.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

[data-component=Button][data-component-type=icon], [data-component=Icon], [data-component=Image], .input-helper-container .error-message > .icon-container,
.form-error-container .error-message > .icon-container, .text-long-form .rating-ranges-table .rating-row .rating-icon, .text-long-form .rating-colors-legend .rating-row .rating-icon {
  line-height: 0.1em;
  display: inline-block;
}

.sr-only,
.accessibility-text, [data-component=Input] input[type=radio] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.non-mobile-padding-horizontal {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media screen and (max-width: 1023px) {
  .mobile-padding-horizontal {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mobile-padding-offset {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .mobile-left-padding-offset {
    margin-left: -1rem;
  }
  .mobile-left-padding {
    padding-left: 1rem;
  }
  .mobile-right-padding-offset {
    margin-right: -1rem;
  }
}
.responsive-left-padding, .responsive-padding, .sticky-section-header > *, [data-component=Layout][data-layout~=col] > *, [data-component=Section] > .body, [data-component=Section] > .footer, [data-component=DataList] .form-container, [data-component=DataList] .datalistitem-row {
  padding-left: 1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding, .responsive-padding, .sticky-section-header > *, [data-component=Layout][data-layout~=col] > *, [data-component=Section] > .body, [data-component=Section] > .footer, [data-component=DataList] .form-container, [data-component=DataList] .datalistitem-row {
    padding-left: 1.5rem;
  }
}

.responsive-left-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
  padding-left: 0.325rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
    padding-left: 1.125rem;
  }
}

.responsive-left-padding-offset, .responsive-padding-offset, [data-component=Layout][data-layout~=col], [data-component=DataList] .form-container, [data-component=DataList] .datalistitem-row, [data-component=DataList][data-row-icon-size=l] .expandable-content {
  margin-left: -1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-offset, .responsive-padding-offset, [data-component=Layout][data-layout~=col], [data-component=DataList] .form-container, [data-component=DataList] .datalistitem-row, [data-component=DataList][data-row-icon-size=l] .expandable-content {
    margin-left: -1.5rem;
  }
}

.responsive-left-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
  margin-left: -0.65rem;
}
@media screen and (min-width: 1024px) {
  .responsive-left-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
    margin-left: -1.125rem;
  }
}

.responsive-right-padding, .responsive-padding, .sticky-section-header > *, [data-component=Layout][data-layout~=col] > *, [data-component=Section] > .body, [data-component=Section] > .footer, [data-component=DataList] .form-container, [data-component=DataList] .text-container, [data-component=DataList][data-row-icon-size=l] .expandable-content {
  padding-right: 1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding, .responsive-padding, .sticky-section-header > *, [data-component=Layout][data-layout~=col] > *, [data-component=Section] > .body, [data-component=Section] > .footer, [data-component=DataList] .form-container, [data-component=DataList] .text-container, [data-component=DataList][data-row-icon-size=l] .expandable-content {
    padding-right: 1.5rem;
  }
}

.responsive-right-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
  padding-right: 0.325rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-narrow, .responsive-padding-narrow, [data-component=Layout][data-layout~=col-tight] > * {
    padding-right: 1.125rem;
  }
}

.responsive-right-padding-offset, .responsive-padding-offset, [data-component=Layout][data-layout~=col], [data-component=DataList] > * {
  margin-right: -1rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-offset, .responsive-padding-offset, [data-component=Layout][data-layout~=col], [data-component=DataList] > * {
    margin-right: -1.5rem;
  }
}

.responsive-right-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
  margin-right: -0.65rem;
}
@media screen and (min-width: 1024px) {
  .responsive-right-padding-offset-narrow, .responsive-padding-offset-narrow, [data-component=Layout][data-layout~=col-tight] {
    margin-right: -1.125rem;
  }
}

.nowrap {
  white-space: nowrap;
}

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .non-mobile-only {
    display: none;
  }
}

[data-position=right] {
  position: absolute;
  right: 12px;
}
@media screen and (max-width: 1023px) {
  [data-position=right] {
    right: 4px;
  }
}

[data-float=right] {
  float: right;
}

a[data-component=Button] {
  text-decoration: none;
}

[data-component=Button][data-component-type=standard][data-component-style=primary]:disabled, [data-component=Button][data-component-type=standard][data-component-style=secondary]:disabled {
  cursor: default;
}

button,
a {
  color: #0A6DE6;
  background-color: inherit;
}
button:hover:not(:disabled),
a:hover:not(:disabled) {
  color: #2056C3;
  background-color: #EEFCFD;
}
button:active:not(:disabled),
a:active:not(:disabled) {
  color: #173F90;
}
button:disabled:hover,
a:disabled:hover {
  -webkit-animation: wiggle 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: wiggle 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
@keyframes wiggle {
  10%, 90% {
    -webkit-transform: translate(-1px, 0);
            transform: translate(-1px, 0);
  }
  20%, 80% {
    -webkit-transform: translate(1px, 0);
            transform: translate(1px, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  40%, 60% {
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
  }
}
button:visited,
a:visited {
  color: #0A6DE6;
}

[data-component=Button] {
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  padding: 0;
  border: none;
  display: inline-block;
  background-color: inherit;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
  border-radius: 2.5rem;
}
[data-component=Button] * {
  pointer-events: none;
}
[data-component=Button][data-component-type=icon] {
  padding: 0.5rem;
  border-radius: 50%;
  line-height: 0.01em;
}
[data-component=Button][data-component-type=icon][aria-expanded=true] svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
[data-component=Button][data-component-type=icon] svg {
  width: 1.5rem;
  height: 1.5rem;
}
[data-component=Button][data-component-type=standard] {
  font-size: 16px;
}
[data-component=Button][data-component-type=standard] > .button-content {
  display: block;
  border-radius: 2.5rem;
}
[data-component=Button][data-component-type=standard] > .button-content * {
  vertical-align: middle;
}
[data-component=Button][data-component-type=standard] [data-component=Icon] {
  margin-right: 0.5rem;
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
[data-component=Button][data-component-type=standard][data-component-style=primary] {
  padding: 0.75rem;
  background-color: #0A6DE6;
  color: white;
}
[data-component=Button][data-component-type=standard][data-component-style=primary]:hover:not(:disabled) {
  background-color: #2056C3;
}
[data-component=Button][data-component-type=standard][data-component-style=primary]:active:not(:disabled) {
  background-color: #173F90;
}
[data-component=Button][data-component-type=standard][data-component-style=primary]:focus {
  -webkit-box-shadow: inset 0 0 0 1px white, 0 0 0 2px #005fcc;
  box-shadow: inset 0 0 0 1px white, 0 0 0 2px #005fcc;
}
[data-component=Button][data-component-type=standard][data-component-style=secondary] > .button-content {
  padding: 0.75rem;
  -webkit-box-shadow: inset 0 0 0 1px;
  box-shadow: inset 0 0 0 1px;
}
[data-component=Button][data-component-type=standard][data-component-style=secondary]:focus {
  -webkit-box-shadow: 0 0 0 1px #005fcc;
  box-shadow: 0 0 0 1px #005fcc;
}
[data-component=Button][data-component-type=standard][data-component-style=text] {
  font-weight: 500;
  padding: 0.75rem 6px;
}
[data-component=Button][data-component-type=usermenu] {
  font-size: 16px;
}
[data-component=Button][data-component-type=usermenu] .label {
  color: #1F323F;
  padding-left: 0.25rem;
}
[data-component=Button][data-component-type=usermenu] > .button-content {
  display: block;
  border-radius: 2.5rem;
  padding: 0.75rem;
}
[data-component=Button][data-component-type=usermenu] > .button-content * {
  vertical-align: middle;
}
[data-component=Button][data-component-type=usermenu] [data-component=Icon] {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
@media screen and (max-width: 900px) {
  [data-component=Button][data-component-type=usermenu] {
    border-radius: 50%;
  }
  [data-component=Button][data-component-type=usermenu] .label {
    padding: 0;
  }
  [data-component=Button][data-component-type=usermenu] [data-component=Icon] {
    margin: 0;
  }
  [data-component=Button][data-component-type=usermenu] .button-content {
    padding: 0.5rem;
  }
  [data-component=Button][data-component-type=usermenu] .responsive {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}
[data-component=Button][data-component-type=usermenu] svg {
  width: 1.5rem;
  height: 1.5rem;
}
[data-component=Button][data-component-type=usermenu][aria-expanded=true] .icon-expand {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
[data-component=Button][data-component-type=iconlabel] {
  font-size: 0.875rem;
  overflow: hidden;
}
[data-component=Button][data-component-type=iconlabel] > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
}
[data-component=Button][data-component-type=iconlabel] [data-component=Icon] {
  margin: initial;
  margin-bottom: 0.5rem;
  width: 3rem;
  height: 3rem;
}
[data-component=Button] [data-component=Icon].progress-icon {
  margin-right: 0;
  margin-bottom: -0.0625rem;
}

@-webkit-keyframes indeterminateProgressIconRotationAnimation {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes indeterminateProgressIconRotationAnimation {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes indeterminateProgressIconDashAnimation {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes indeterminateProgressIconDashAnimation {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
[data-progress] [data-component=Icon] {
  display: inline-block;
}
[data-progress] [data-component=Icon].progress-icon {
  display: none;
}

[data-progress=true][data-progress-value=indeterminate] .progress-icon circle {
  fill: none;
  stroke: currentColor;
  r: 19;
  stroke-width: 3;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation: indeterminateProgressIconRotationAnimation 2.25s linear infinite, indeterminateProgressIconDashAnimation 1.5s ease-in-out infinite;
          animation: indeterminateProgressIconRotationAnimation 2.25s linear infinite, indeterminateProgressIconDashAnimation 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
[data-progress=true]:disabled {
  opacity: 1;
}
[data-progress=true]:disabled:hover {
  -webkit-animation: none;
          animation: none;
}
[data-progress=true] [data-component=Icon] {
  display: none;
}
[data-progress=true] [data-component=Icon].progress-icon {
  display: inline-block;
}
[data-progress=true] .label {
  display: none;
}

.ui-radio {
  border-radius: 50%;
  background-clip: padding-box;
}

.ui-radio-group-stacked {
  margin-bottom: 1.5rem;
  text-align: left;
}
.ui-radio-group-stacked > * {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ccc;
}
.ui-radio-group-stacked > * label {
  padding-left: 0.5rem;
}

.ui-radio-item {
  cursor: pointer;
}
.ui-radio-item:hover {
  background-color: white;
}

.ui-radio {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #0A6DE6;
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  margin: 2px;
  margin-right: 0.75rem;
  margin-left: 0;
  background-color: white;
}

input[type=radio] {
  height: 1rem;
  width: 1rem;
}

input[type=radio]:checked + label .ui-radio {
  -webkit-box-shadow: inset 0 0 0 3px white;
  box-shadow: inset 0 0 0 3px white;
  background-color: #0A6DE6;
}

input[type=radio]:focus + label .ui-radio {
  -webkit-box-shadow: inset 0 0 0 3px white, 0 0 0 3px #0A6DE6;
  box-shadow: inset 0 0 0 3px white, 0 0 0 3px #0A6DE6;
}

.login-verify-main #sb-verify-btn {
  margin: 16px 0 8px;
}
.login-verify-main .text-confirm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.login-verify-main .text-confirm > svg {
  margin: 0 10px;
  width: 5rem;
  height: 3rem;
}
.login-verify-main .text-confirm path {
  fill: green;
}
.login-verify-main [data-component=Button][data-component-type=standard] {
  margin-top: 8px;
}
.login-verify-main [data-component=Button][data-component-type=standard] > .button-content {
  -webkit-box-shadow: initial;
          box-shadow: initial;
  font-weight: 500;
}
.login-verify-main form {
  margin-bottom: 0;
}
.login-verify-main .remember-box input {
  clip: rect(0, 0, 0, 0);
  border: 0;
  display: inline-block;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.login-verify-main .remember-box input:focus ~ label [data-component=Icon] {
  -webkit-box-shadow: inset 0 0 0 2px #0a6de6;
          box-shadow: inset 0 0 0 2px #0a6de6;
}
.login-verify-main .remember-box label {
  cursor: pointer;
}
.login-verify-main .remember-box svg {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

#modal-mfa-email .content-wrapper {
  margin-top: 24px;
}
#modal-mfa-email .radio-wrapper label {
  display: block;
  padding-bottom: 16px;
}