[data-component=Stepper] {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 1em;
}
[data-component=Stepper] li {
  margin-bottom: 1em;
}
[data-component=Stepper] li:last-of-type {
  margin-bottom: 0;
}
[data-component=Stepper] h2 {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 1em;
  outline: none;
}
[data-component=Stepper] h2 .step-circle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 12px;
  background-color: #F7F7F8;
}
[data-component=Stepper] h2 .step-circle .stepValidCheck {
  display: inline-block;
  height: 24px;
  width: 24px;
  position: relative;
  top: 8px;
}
[data-component=Stepper] h2 .step-circle .stepValidCheck path {
  fill: #1F323F;
}
[data-component=Stepper] h2.current .step-circle {
  background-color: #0A6DE6;
  color: #fff;
}
[data-component=Stepper] h3 {
  font-size: 14px;
  line-height: 20px;
  color: #5C6C75;
  font-weight: 400;
}
[data-component=Stepper] .data-row {
  margin-bottom: 1em;
}
[data-component=Stepper] [aria-current=step] h2 .step-circle {
  background-color: #0A6DE6;
  color: #fff;
}

[data-component=Input] > input {
  speak: spell-out;
}

#login-continue-btn {
  display: block;
  text-align: center;
}

[data-show=false] {
  display: none;
}