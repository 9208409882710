.sr-only,
.accessibility-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/*
# CHECKOUT IMPROVEMENTS
- Moved payment method to after summary
- Changed order card title to order Summary
- Removed Summary sub heading

# ADA CSS UPDATES
- Converted shopping card summaries to tables
- Fixed Sign up checkbox label <a> focus
- Fix alert body text <a> focus
- Fixed Need help logging in? <a> focus
- Fixed form text field label help (icon) button focus
- Added aria-label="Close" to every close button in the HTML
- Modified .button-close <div>s (tags) in the HTML to <button>s (tags)
- Modified <body> text color to hsl(0%,0,10%);
- Modified Legal disclosures link color
- Modified interactive color
- Modified all buttons to blue their associated states (hover, active, etc.)
- Modified focus color
- Consolidated focus affordance for all elements
- Form input placeholder color issue: Please remove placeholders altogether and update labels accordingly: move placeholder text to end of label, encased in parentheses
- Modified show password button icon color
- Modified background color to #F7F7F8
- Modified steps color
- Updated .purchase-path-card .header .title properties to accommodate for changing the tag from<div> to <h2> 
- Changed input label text color to $text-color and font-weight to 600;
*/
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body,
.page {
  /* needed for proper flexbox layout */
  height: 100%;
}

html .device-ios {
  font-family: -apple-system, BlinkMacSystemFont;
}
html .device-android {
  font-family: Outfit, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
}

body {
  font-family: "Outfit", sans-serif;
}

#forum-register {
  padding: 3em 10px 8px;
  background-color: #F7F7F8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#forum-register #confirmation-register {
  padding: 1.5em;
}
#forum-register #confirmation-register h1 {
  padding: 8px 0;
  font-weight: 500;
}
#forum-register #confirmation-register h2 {
  padding: 1em 0;
}
#forum-register #confirmation-register p {
  padding: 0;
  margin: 0;
}
#forum-register #confirmation-register a {
  margin: 2em 0 0;
  padding: 12px 20px;
}
#forum-register h1, #forum-register h2 {
  display: block;
  width: 100%;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  padding: 1em;
  padding-left: 16px;
}
@media screen and (max-width: 600px) {
  #forum-register h1, #forum-register h2 {
    padding: 1em 0;
    font-size: 18px;
    line-height: 22px;
  }
}
#forum-register * {
  font-family: "Outfit", sans-serif !important;
}
#forum-register .interactive {
  cursor: pointer;
}
#forum-register [empty=true] .pw-critera {
  color: rgba(26, 26, 26, 0.75);
}
#forum-register [empty=false] .pw-critera {
  color: #da1111;
}
#forum-register [empty=false] .pw-critera[valid=true] {
  color: #238c4d;
}
#forum-register .legal-disclosures {
  font-size: 16px;
}
#forum-register .legal-disclosures a {
  color: #1a1a1a;
  text-decoration: underline;
}
#forum-register .legal-disclosures a:focus {
  padding: 0 0.25em;
  margin: 0 -0.25em;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 2px #0A6DE6;
          box-shadow: 0 0 0 2px #0A6DE6;
  outline: none;
}
#forum-register .legal-disclosures .title {
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 0.5em;
}
#forum-register .legal-disclosures p {
  line-height: 1.5em;
  padding-bottom: 1em;
  margin: 0;
}
@media screen and (min-width: 601px) {
  #forum-register .legal-disclosures p:last-of-type {
    padding-bottom: 0;
  }
}
#forum-register .secure-badge {
  line-height: 0.1em;
  margin-top: 8px;
  margin-right: auto;
  margin-bottom: -16px;
  margin-left: auto;
  max-width: 300px;
}
#forum-register .secure-badge a {
  display: inline-block;
  outline: none;
}
#forum-register .secure-badge a:focus {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 2px #0A6DE6;
          box-shadow: 0 0 0 2px #0A6DE6;
  outline: none;
}
#forum-register .secure-badge a:focus svg {
  outline: none;
}
#forum-register .secure-badge svg {
  display: inline-block;
  padding: 0.125rem;
  margin: -0.125rem;
  width: 100%;
  height: 63px;
}
@media screen and (min-width: 601px) {
  #forum-register .secure-badge {
    margin-bottom: -16px;
  }
}
#forum-register button.link {
  text-decoration: underline;
  font-size: inherit !important;
  padding: 0;
  color: #0A6DE6;
  vertical-align: middle;
  line-height: 0.01em;
  margin-left: 0.125rem;
  border: 1px solid #0A6DE6;
  background-color: transparent;
  font-size: 16px;
  border-radius: 50%;
}
#forum-register button.link:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px #0A6DE6;
          box-shadow: 0 0 0 3px #0A6DE6;
}
#forum-register .button {
  -webkit-transition: background-color 0.125s;
  transition: background-color 0.125s;
  -webkit-appearance: none;
  border: none;
  border-radius: 4px;
  line-height: 1em;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
#forum-register .button:not(.link) {
  color: #fff;
  background-color: #0A6DE6;
  font-size: 16px;
  border-radius: 1.375rem;
  padding: 12px 20px;
}
#forum-register .button:not(.link):hover {
  background-color: #2056c3;
}
#forum-register .button:not(.link):active {
  background-color: #173f90;
  -webkit-box-shadow: none;
          box-shadow: none;
  moz-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
}
#forum-register .button:not(.link):focus {
  border-radius: 1.375rem;
  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #0A6DE6;
          box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #0A6DE6;
}
#forum-register .button.inline {
  display: inline-block;
}
#forum-register .button.inline:hover {
  text-decoration: none;
}
#forum-register .button:disabled, #forum-register .button.disabled {
  opacity: 0.5;
}
#forum-register .button:focus {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 2px #0A6DE6;
          box-shadow: 0 0 0 2px #0A6DE6;
  outline: none;
}
#forum-register .button.ghost {
  background-color: transparent;
  color: #0A6DE6;
}
#forum-register .button.ghost:hover {
  background-color: hsla(0, 0%, 0%, 0.1);
}
#forum-register .button.ghost:active {
  background-color: hsla(0, 0%, 0%, 0.25);
  -webkit-box-shadow: none;
          box-shadow: none;
  moz-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2);
}
#forum-register .button.ghost:focus {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 2px #0A6DE6;
          box-shadow: 0 0 0 2px #0A6DE6;
  outline: none;
}
#forum-register .cta {
  font-weight: 400;
  text-decoration: none;
  width: 100%;
}
.device-android #forum-register .cta:not(.link) {
  text-transform: uppercase;
}
#forum-register .cta:focus {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 4px #0A6DE6;
          box-shadow: 0 0 0 1px #fff, 0 0 0 4px #0A6DE6;
}
@media screen and (min-width: 601px) {
  #forum-register .cta {
    font-size: 20px;
  }
}
#forum-register .purchase-path-card {
  margin: 0 auto;
  max-width: 800px;
  border-radius: 16px;
  margin-bottom: 16px;
  color: #1a1a1a;
  background-color: #fff;
}
#forum-register .purchase-path-card .form-field .icon-secure {
  right: 0;
  top: 0;
}
#forum-register .purchase-path-card .icon-secure {
  line-height: 0.1em;
  position: absolute;
  top: 12px;
  right: 12px;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card .icon-secure {
    top: 16px;
    right: 16px;
  }
}
#forum-register .purchase-path-card .icon-secure svg {
  width: 28px;
  height: 28px;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card .icon-secure svg {
    width: 32px;
    height: 32px;
  }
}
#forum-register .purchase-path-card > .header {
  position: relative;
  padding: 12px 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom: solid 1px hsl(0, 0%, 75%);
  border-bottom: solid 1px #d5dada;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card > .header {
    padding: 16px 32px;
  }
}
#forum-register .purchase-path-card > .header .title {
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  padding: 8px 0;
}
@media screen and (max-width: 600px) {
  #forum-register .purchase-path-card > .header .title {
    font-size: 18px;
    line-height: 22px;
  }
}
#forum-register .purchase-path-card > .body {
  position: relative;
  font-size: 16px;
  line-height: 22px;
  padding: 16px;
  padding-bottom: 0;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card > .body {
    padding: 32px;
  }
}
#forum-register .purchase-path-card .log-in-tab-footer {
  text-align: center;
  margin-top: 8px;
  margin-right: -16px;
  margin-bottom: -16px;
  margin-left: -16px;
  background-color: #eff1f1;
  border-top: solid 1px #d5dada;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card .log-in-tab-footer {
    margin-top: 48px;
    margin-right: -32px;
    margin-bottom: -32px;
    margin-left: -32px;
  }
}
#forum-register .purchase-path-card .log-in-tab-footer .link {
  font-size: 16px;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card .log-in-tab-footer .link {
    padding: 24px 0;
    font-size: 17px;
    line-height: 20px;
  }
}
#forum-register .purchase-path-card .log-in-tab-footer .link a {
  color: #0A6DE6;
}
#forum-register .purchase-path-card > .footer .slot-1 {
  padding-top: 0;
  background-color: #fff;
}
#forum-register .purchase-path-card > .footer .slot-2 {
  text-align: center;
}
.form-1-col-wrapper #forum-register .purchase-path-card > .footer .slot-2 {
  border-top: solid 1px hsl(0, 0%, 75%);
}
@media screen and (max-width: 600px) {
  #forum-register .purchase-path-card > .footer .slot-2 {
    background-color: #eff1f1;
    border-top: solid 1px #d5dada;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
#forum-register .purchase-path-card > .footer .slot-2 .link {
  font-size: 16px;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card > .footer .slot-2 .link {
    cursor: pointer;
    padding: 24px 0;
    font-size: 17px;
    line-height: 20px;
  }
}
#forum-register .purchase-path-card > .footer .slot-2 .link a {
  color: #0071ed;
}
@media screen and (min-width: 601px) {
  #forum-register .purchase-path-card > .footer.layout-2-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  #forum-register .purchase-path-card > .footer.layout-2-col > * {
    padding: 0 32px 32px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
  #forum-register .purchase-path-card > .footer.layout-2-col .slot-1 {
    padding-right: 16px;
    border: none;
    background-color: transparent;
  }
  #forum-register .purchase-path-card > .footer.layout-2-col .slot-2 {
    padding-left: 16px;
  }
}
@media screen and (max-width: 600px) {
  #forum-register .purchase-path-card > .footer.layout-2-col .slot-1 {
    padding: 0 16px 32px;
  }
}
@media screen and (min-width: 601px) {
  #forum-register .layout-2-col form .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  #forum-register .layout-2-col form .form-group > .form-item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
  }
  #forum-register .layout-2-col form .form-group > .form-item.full {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
#forum-register .form-field-message,
#forum-register .form-item-message {
  display: none;
}
#forum-register .form-field-message.error,
#forum-register .form-item-message.error {
  display: block;
}
#forum-register .form-item-block {
  -ms-flex-preferred-size: 100% !important;
      flex-basis: 100% !important;
  padding-top: 16px;
}
#forum-register .form-item-block .title {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
#forum-register .form-item-block p {
  margin: 0;
  padding-bottom: 0 !important;
  padding-top: 4px;
}
#forum-register form .form-group > .form-item.shim {
  padding: 0;
}
#forum-register form .form-group > .form-item[class*=error-] .form-item-message, #forum-register form .form-group > .form-item[error-child] .form-item-message {
  display: inline-block;
  width: 100%;
}
#forum-register form .form-group > .form-item.error-1 .form-field:first-of-type .textfield, #forum-register form .form-group > .form-item[error-child="1"] .form-field:first-of-type .textfield {
  -webkit-box-shadow: 0 2px 0 #ed1818;
          box-shadow: 0 2px 0 #ed1818;
}
#forum-register form .form-group > .form-item.error-1 .form-field:first-of-type .textfield:focus, #forum-register form .form-group > .form-item[error-child="1"] .form-field:first-of-type .textfield:focus {
  -webkit-box-shadow: 0 4px 0 #ed1818;
          box-shadow: 0 4px 0 #ed1818;
}
#forum-register form .form-group > .form-item.error-1 .form-field:first-of-type select, #forum-register form .form-group > .form-item[error-child="1"] .form-field:first-of-type select {
  -webkit-box-shadow: 0 2px 0 0 #ed1818;
          box-shadow: 0 2px 0 0 #ed1818;
}
#forum-register form .form-group > .form-item.error-1 .form-field:first-of-type select:focus, #forum-register form .form-group > .form-item[error-child="1"] .form-field:first-of-type select:focus {
  -webkit-box-shadow: 0 4px 0 0 #ed1818;
          box-shadow: 0 4px 0 0 #ed1818;
}
#forum-register form .form-group > .form-item.error-2 .form-field:nth-of-type(2) .textfield, #forum-register form .form-group > .form-item[error-child="2"] .form-field:nth-of-type(2) .textfield {
  -webkit-box-shadow: 0 2px 0 #ed1818;
          box-shadow: 0 2px 0 #ed1818;
}
#forum-register form .form-group > .form-item.error-2 .form-field:nth-of-type(2) .textfield:focus, #forum-register form .form-group > .form-item[error-child="2"] .form-field:nth-of-type(2) .textfield:focus {
  -webkit-box-shadow: 0 4px 0 #ed1818;
          box-shadow: 0 4px 0 #ed1818;
}
#forum-register form .form-group > .form-item.error-2 .form-field:nth-of-type(2) select, #forum-register form .form-group > .form-item[error-child="2"] .form-field:nth-of-type(2) select {
  -webkit-box-shadow: 0 2px 0 0 #ed1818;
          box-shadow: 0 2px 0 0 #ed1818;
}
#forum-register form .form-group > .form-item.error-2 .form-field:nth-of-type(2) select:focus, #forum-register form .form-group > .form-item[error-child="2"] .form-field:nth-of-type(2) select:focus {
  -webkit-box-shadow: 0 4px 0 0 #ed1818;
          box-shadow: 0 4px 0 0 #ed1818;
}
#forum-register form .form-group > .form-item.age-section, #forum-register form .form-group > .form-item.email-section, #forum-register form .form-group > .form-item.agree-section {
  padding: 8px;
}
#forum-register form .form-group > .form-item.age-section {
  padding-top: 16px;
}
#forum-register form .form-group > .form-item.agree-section {
  padding-bottom: 16px;
}
#forum-register form .form-group > .form-item .form-field-message,
#forum-register form .form-group > .form-item .form-item-message {
  margin-top: 2px;
  color: #c20f0f;
  background-color: #fcdfdf;
  padding: 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
#forum-register form .form-group > .form-item .form-field-message .icon,
#forum-register form .form-group > .form-item .form-item-message .icon {
  width: 24px;
  vertical-align: top;
  margin-right: 8px;
}
#forum-register form .form-group > .form-item .form-field-message a,
#forum-register form .form-group > .form-item .form-item-message a {
  color: #910b0b;
  font-weight: 600;
}
@media screen and (max-width: 600px) {
  #forum-register form .form-group > .form-item {
    padding-bottom: 2em;
  }
  #forum-register form .form-group > .form-item.space-holder {
    display: none;
  }
}
@media screen and (min-width: 601px) {
  #forum-register form .form-group {
    margin: -16px;
  }
  #forum-register form .form-group > .form-item {
    padding: 16px;
  }
  .body-id-questions #forum-register form .form-group > .form-item {
    padding-bottom: 0;
  }
}
#forum-register form .form-group .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
#forum-register form .form-group .form-item-2-col-inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
#forum-register form .form-group .form-item-2-col-inner-wrapper > :nth-child(2) {
  padding-left: 16px;
}
@media screen and (min-width: 321px) {
  #forum-register form .form-group .form-item-2-col-inner-wrapper > :nth-child(2) {
    padding-left: 32px;
  }
}
#forum-register form .form-group .form-item-2-col-inner-wrapper .input-dl-number {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
#forum-register form .form-group .form-item-2-col-inner-wrapper .input-dl-state {
  -ms-flex-preferred-size: 132px;
      flex-basis: 132px;
  margin-right: -28px;
}
#forum-register form .form-group .form-item-2-col-inner-wrapper > .input-name-mi {
  -ms-flex-preferred-size: 86px;
      flex-basis: 86px;
}
#forum-register form .form-group .form-item-2-col-inner-wrapper > .input-name-suffix {
  -ms-flex-preferred-size: 173px;
      flex-basis: 173px;
  margin-right: -48px;
}
@media screen and (min-width: 321px) {
  #forum-register form .form-group .form-item-2-col-inner-wrapper > .input-name-suffix {
    margin-right: -16px;
  }
}
@media screen and (min-width: 600px) and (max-width: 670px) {
  #forum-register form .form-group .form-item-2-col-inner-wrapper > .input-name-suffix {
    -ms-flex-preferred-size: 193px;
        flex-basis: 193px;
  }
}
#forum-register form .form-group .form-item-2-col-inner-wrapper > .input-zip-code {
  -ms-flex-preferred-size: 196px;
      flex-basis: 196px;
  padding-right: 32px;
}
#forum-register form .form-group .form-item-2-col-inner-wrapper > .input-gender {
  -ms-flex-preferred-size: 195px;
      flex-basis: 195px;
  margin-right: -48px;
}
@media screen and (min-width: 321px) {
  #forum-register form .form-group .form-item-2-col-inner-wrapper > .input-gender {
    margin-right: -16px;
  }
}
#forum-register form .form-group .form-item-2-col-inner-wrapper + .form-field-message,
#forum-register form .form-group .form-item-2-col-inner-wrapper + .form-item-message {
  margin-top: -14px;
  margin-bottom: 16px;
}
#forum-register form .form-group .form-label {
  color: #0A6DE6;
  display: block;
  font-size: 16px;
}
#forum-register form .form-group .form-label .optional {
  color: #a3a3a3;
  font-size: 0.85em;
}
#forum-register form .form-group .form-label.disabled {
  color: #a3a3a3 !important;
}
@media screen and (min-width: 321px) {
  #forum-register form .form-group .form-label {
    font-size: 18px;
  }
}
#forum-register form .form-group .form-field {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}
#forum-register form .form-group .form-field.error .form-field-message, #forum-register form .form-group .form-field.error .form-item-message {
  display: block;
}
#forum-register form .form-group .error .textfield {
  -webkit-box-shadow: 0 2px 0 #ed1818;
          box-shadow: 0 2px 0 #ed1818;
}
#forum-register form .form-group .error .textfield:focus {
  -webkit-box-shadow: 0 4px 0 #ed1818;
          box-shadow: 0 4px 0 #ed1818;
}
#forum-register form .form-group input[type=text] {
  background-color: transparent;
}
#forum-register form .form-group .textfield {
  -webkit-appearance: none;
  border-radius: 0;
  position: relative;
  width: 100%;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 4px 0 8px 0;
  -webkit-box-shadow: 0 2px 0 #dde2e2;
          box-shadow: 0 2px 0 #dde2e2;
  background-color: transparent;
  background-color: inherit;
}
#forum-register form .form-group .textfield::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}
#forum-register form .form-group .textfield::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}
#forum-register form .form-group .textfield:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}
#forum-register form .form-group .textfield::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}
#forum-register form .form-group .textfield::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a9a9a9;
  opacity: 1; /* Firefox */
}
#forum-register form .form-group .textfield:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9a9a9;
}
#forum-register form .form-group .textfield::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a9a9a9;
}
.device-ios #forum-register form .form-group .textfield {
  font-family: -apple-system, BlinkMacSystemFont;
}
.device-android #forum-register form .form-group .textfield {
  font-family: Outfit, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
}
@media screen and (min-width: 321px) {
  #forum-register form .form-group .textfield {
    font-size: 20px;
  }
}
#forum-register form .form-group .textfield:focus {
  -webkit-box-shadow: 0 4px 0 #0A6DE6;
          box-shadow: 0 4px 0 #0A6DE6;
}
#forum-register form .form-group .textfield:disabled {
  -webkit-box-shadow: none;
          box-shadow: none;
}
#forum-register .select-item {
  width: 100%;
}
#forum-register .select-drop-arrow {
  position: absolute;
  right: 5px;
  top: 30px;
}
#forum-register .hide {
  display: none !important;
}
#forum-register .ui-checkbox-wrapper {
  line-height: 1.5em;
  display: inline-block;
}
#forum-register .ui-checkbox-wrapper a {
  color: inherit;
  text-decoration: underline;
}
#forum-register .ui-checkbox-wrapper label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#forum-register .ui-checkbox-wrapper label a:focus {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 3px #0A6DE6;
  box-shadow: 0 0 0 3px #0A6DE6;
  outline: none;
}
#forum-register .ui-checkbox-wrapper label.chk-label {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
}
#forum-register .ui-checkbox-wrapper.block {
  display: block;
  margin-bottom: 16px;
}
#forum-register .indented .ui-checkbox-wrapper > * {
  display: table-cell;
}
#forum-register .ui-checkbox-wrapper.indented > * {
  display: table-cell;
}
#forum-register .ui-checkbox-label {
  line-height: inherit;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#forum-register .ui-checkbox-label svg {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 2px #869898 inset;
  box-shadow: 0 0 0 2px #869898 inset;
  position: relative;
  top: 4px;
  margin-right: 8px;
  width: 18px;
  height: 18px;
  pointer-events: none;
}
#forum-register .ui-checkbox-label input + span svg {
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}
#forum-register .ui-checkbox-label input + span svg path, #forum-register .ui-checkbox-label input + span svg rect {
  display: none;
}
#forum-register .ui-checkbox-label input {
  display: block;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
#forum-register .ui-checkbox-label input:checked + span svg rect, #forum-register .ui-checkbox-label input:checked + span svg path {
  display: inline-block;
  stroke-linecap: square;
}
#forum-register .ui-checkbox-label input:checked + span svg rect {
  stroke: none;
}
#forum-register .ui-checkbox-label input:checked + span svg path {
  stroke: #0A6DE6;
  stroke-width: 2px;
}
#forum-register .ui-checkbox-label input:disabled + span svg {
  opacity: 0.4;
  cursor: default;
}
#forum-register .ui-checkbox-label input:focus + span svg {
  -webkit-box-shadow: 0 0 0 2px #869898 inset, 0 0 0 3px #0A6DE6;
  box-shadow: 0 0 0 2px #869898 inset, 0 0 0 3px #0A6DE6;
}
#forum-register .ui-checkbox-label input:active + span svg {
  -webkit-box-shadow: 0 0 0 2px #869898 inset, 0 0 0 2px #0A6DE6;
  box-shadow: 0 0 0 2px #869898 inset, 0 0 0 2px #0A6DE6;
}
#forum-register .help {
  font-size: 16px;
  line-height: 1.25em;
  text-align: center;
  margin-bottom: 16px;
  font-weight: 600;
}
#forum-register .help a:focus {
  border-radius: 3px;
  -webkit-box-shadow: 0 0 0 2px #0A6DE6;
          box-shadow: 0 0 0 2px #0A6DE6;
  outline: none;
}
@media screen and (min-width: 601px) {
  #forum-register .help {
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
}
#forum-register .js-mask-toggle {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
#forum-register .js-mask-toggle input[type=checkbox] {
  position: absolute;
  opacity: 0;
  right: 0;
}
#forum-register .js-mask-toggle input[type=checkbox]:focus + label {
  -webkit-box-shadow: 0 0 0 3px #0A6DE6;
          box-shadow: 0 0 0 3px #0A6DE6;
}
#forum-register .js-mask-toggle input[type=checkbox]:active + label {
  background-color: #bfc5c6;
}
#forum-register .js-mask-toggle input[type=checkbox] + label {
  -webkit-transition: all 0.125s;
  transition: all 0.125s;
}
#forum-register .js-mask-toggle input[type=checkbox] + label .checked {
  display: none;
}
#forum-register .js-mask-toggle input[type=checkbox] + label .unchecked {
  display: inline;
}
#forum-register .js-mask-toggle input[type=checkbox]:checked + label .unchecked {
  display: none;
}
#forum-register .js-mask-toggle input[type=checkbox]:checked + label .checked {
  display: inline;
}
#forum-register .js-mask-toggle label,
#forum-register .js-mask-toggle .label {
  font-size: 15px;
  text-align: center;
  z-index: 100;
  color: #0A6DE6;
  border-radius: 3px;
  line-height: 1em;
  display: block;
}
#forum-register .js-mask-toggle label svg,
#forum-register .js-mask-toggle .label svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
#forum-register .js-mask-toggle.interactive {
  border: none;
  right: 0;
  position: absolute;
  border-radius: 2px;
  display: inline-block;
  padding: 4px;
}
#forum-register .js-mask-toggle.interactive:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 3px #0A6DE6;
          box-shadow: 0 0 0 3px #0A6DE6;
}
#forum-register .js-mask-toggle.interactive:focus span {
  background-color: #EEFCFD;
}
#forum-register input ~ .validation-message {
  margin-bottom: 0;
  border: 1px solid transparent;
}
#forum-register .validation-message {
  color: rgba(26, 26, 26, 0.75);
  padding: 8px;
  background-color: #F7F7F8;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
#forum-register .validation-message svg {
  height: 24px;
  width: 24px;
  margin-right: 4px;
}
@media screen and (min-width: 601px) {
  #forum-register .validation-message {
    padding: 16px;
    padding-bottom: 4px;
  }
}
@media screen and (max-width: 425px) {
  #forum-register .validation-message {
    display: none;
  }
}
#forum-register .validation-message ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#forum-register .validation-message ul li {
  margin-bottom: 0.25em;
}
@media screen and (min-width: 601px) {
  #forum-register .validation-message ul li {
    margin-bottom: 0.75em;
  }
}
#forum-register .validation-message ul li > * {
  vertical-align: top;
}
#forum-register .validation-message [empty-field=false] [validates=false] {
  color: #da1111;
}
#forum-register .validation-message [empty-field=false] [validates=true] {
  color: #238c4d;
}
#forum-register .validation-message [validates=false] .checked {
  display: none;
}
#forum-register .validation-message [validates=true] .unchecked {
  display: none;
}
#forum-register .validation-message .icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
#forum-register .password-group input:focus ~ .validation-message {
  display: block;
}
#forum-register select::-ms-expand {
  display: none;
}
#forum-register select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  background-color: transparent;
  font-size: 18px;
  padding: 4px 24px 8px 0;
  margin-left: 0;
  margin-right: 8px;
  border: none;
  -webkit-box-shadow: 0 2px 0 0 #dde2e2;
          box-shadow: 0 2px 0 0 #dde2e2;
}
.device-ios #forum-register select {
  font-family: -apple-system, BlinkMacSystemFont;
}
.device-android #forum-register select {
  font-family: Outfit, Oxygen-Sans, Ubuntu, Cantarell, sans-serif;
}
@media screen and (min-width: 321px) {
  #forum-register select {
    font-size: 20px;
  }
}
#forum-register select:focus {
  -webkit-box-shadow: 0 4px 0 0 #0A6DE6;
          box-shadow: 0 4px 0 0 #0A6DE6;
  outline: none;
  border: none;
}
.error #forum-register select:focus {
  -webkit-box-shadow: 0 4px 0 0 #ed1818;
          box-shadow: 0 4px 0 0 #ed1818;
}
#forum-register select:disabled {
  opacity: 0.5;
  cursor: default;
}
#forum-register select:disabled + .select-label:after {
  border-color: transparent;
}
#forum-register select:invalid {
  color: #ababab;
}
.error #forum-register select {
  -webkit-box-shadow: 0 2px 0 0 #ed1818;
          box-shadow: 0 2px 0 0 #ed1818;
}
#forum-register .select-label:after {
  pointer-events: none;
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  margin-bottom: 1px;
  margin-left: -28px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #0A6DE6;
}
#forum-register .nowrap {
  white-space: nowrap;
  display: inline-block;
}
@media screen and (max-width: 600px) {
  #forum-register .hide-on-small {
    display: none;
  }
}
@media screen and (min-width: 601px) {
  #forum-register .hide-on-large {
    display: none;
  }
}
#forum-register a[href],
#forum-register input[type=submit],
#forum-register input[type=image],
#forum-register select,
#forum-register button,
#forum-register label,
#forum-register .pointer {
  cursor: pointer;
}
#forum-register .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
@media print {
  #forum-register .sr-only {
    display: none;
  }
}
#forum-register .no-wrap {
  white-space: nowrap;
}
#forum-register .disabled-state {
  opacity: 0.5;
  cursor: default;
}