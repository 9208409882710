@media screen and (max-width: 767px) {
  .react-responsive-modal-container {
    height: initial;
  }
}

.myf-simple-overlay .modal .link {
  padding: 12px 6px;
  color: #0A6DE6;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.myf-simple-overlay .modal .primary {
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  .page-modal-left-wrapper {
    max-height: 100vh;
    margin: 0;
    height: 100vh;
    width: 100%;
  }
}
.page-modal-left-wrapper .react-responsive-modal-closeButton {
  right: 0;
  left: 14px;
}
.page-modal-left-wrapper .react-responsive-modal-closeButton svg {
  margin-top: 3px;
  fill: #0A6DE6;
}
.page-modal-left-wrapper .modal h1 {
  margin-left: 48px;
  font-size: 20px;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .page-modal-left-wrapper .modal .content-wrapper {
    padding-right: 2.2rem;
  }
}
.page-modal-left-wrapper .modal .content-wrapper .full-width-btn button {
  width: 100%;
}